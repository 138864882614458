import { useState } from 'react';
import useRouter from '@/Framework/hooks/useNextRouter';
import { useDIContext } from '@/Framework/DI/DIContext';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getErrorMessage, getMessage } from '@/Framework/Message/Mapper/getMessage';
import { useUploadContext } from '@/allocate/application/Allocations/Upload/UploadContext';
import AllocationsUploadRepository from '@/allocate/infrastructure/repository/AllocationsUploadRepository';
import AllocationsAnalyticsRepository from '@/allocate/infrastructure/repository/AllocationsAnalyticsRepository';
import { IEnhancedTagging } from '@/allocate/domain/vo/Allocations/Upload/EnhancedTagging';
import { filterEnhancedTaggingUnderwriters } from '@/allocate/ui/components/shared/Upload/EnhancedTagging/helpers';
import { getAllocateAnalyticsUrl } from '@/allocate/application/Allocations/Upload/config';
import { messageCodes } from '@/Framework/Message/messages';

const initialValues = {
  transactionTypeId: '',
  sponsorId: null,
  subsectorId: null,
  subindustryId: null,
  countryId: null,
  dealSize: null,
  currencyId: null,
  underwriters: [],
};

const useEnhancedTagging = () => {
  const { push } = useRouter();
  const { container } = useDIContext();
  const allocationsUploadRepository = container.get<AllocationsUploadRepository>(AllocationsUploadRepository);
  const allocationsAnalyticsRepository = container.get<AllocationsAnalyticsRepository>(AllocationsAnalyticsRepository);

  const {
    isEditing,
    isReimport,
    ownerId,
    dealAllocationId,
    dealAllocationName,
    linkedRoadshowId,
    importAllocations,
    setIsLeaveModalDisabled,
  } = useUploadContext();

  const [enhancedTaggingData, setEnhancedTaggingData] = useState<IEnhancedTagging>(initialValues);
  const [isFetching, setIsFetching] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Get enhanced tagging data
   */
  const getEnhancedTagging = async (): Promise<void> => {
    try {
      const { enhancedTagging } = await allocationsAnalyticsRepository.getDealAllocationDetails({
        dealAllocationId,
      });
      setEnhancedTaggingData(enhancedTagging);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  };

  /**
   * Save enhanced tagging
   */
  const saveEnhancedTagging = async (enhancedTagging: IEnhancedTagging): Promise<void> => {
    setIsSubmitting(true);
    setIsLeaveModalDisabled(true);
    try {
      if (!linkedRoadshowId) {
        await allocationsUploadRepository.saveEnhancedTagging({
          ...enhancedTagging,
          sponsorName: enhancedTagging.sponsorName || null,
          underwriters: filterEnhancedTaggingUnderwriters(enhancedTagging.underwriters),
          dealSize: enhancedTagging.dealSize
          ? Number(enhancedTagging.dealSize.toString().replace(/[^\d]/g, ''))
          : null,
          dealAllocationId,
          ownerId,
        });
      }

      if (isEditing || isReimport) {
        const message = isReimport
          ? messageCodes.ALLOCATE_ALLOCATIONS_REIMPORTED
          : messageCodes.ALLOCATE_ALLOCATIONS_UPDATED;
        NotificationManager.success(getMessage(message, { dealAllocationName }));
        setTimeout(() => { push(getAllocateAnalyticsUrl(dealAllocationId)); }, 100);
      } else {
        importAllocations();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
      setIsSubmitting(false);
    }
  };

  return {
    getEnhancedTagging,
    saveEnhancedTagging,
    enhancedTaggingData,
    isFetching,
    isSubmitting,
  };
};

export default useEnhancedTagging;
