import React from 'react';
import memoize from '@/Framework/dataHelpers/memoize';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import keyResolver from '@/Framework/dataHelpers/keyResolver';
import SharedFilterGroupItem from '@/ui/shared/modules/Filters/components/FilterGroupItem';

const getChildren = memoize((nestedIds, absSubSectorById, formValues, filterByChildrenName) => {
  const children = nestedIds.map((absSubSectorId) => ({
    value: absSubSectorId,
    label: absSubSectorById[absSubSectorId].absSubSectorName,
    disabled: !absSubSectorById[absSubSectorId].canChangeResponse,
  }));
  const activeChildrenCount = children.filter((child) => !child.disabled).length;
  const checkedChildrenCount = children.filter(
    (child) => !child.disabled && formValues[filterByChildrenName].includes(child.value),
  ).length;

  return {
    children,
    activeChildrenCount,
    checkedChildrenCount,
  };
}, keyResolver);

const FilterGroupItem = (props) => {
  const { value, formValues, filterByChildrenName } = props;
  const { absSectorFilterList, absSubSectorFilterList } = useGlobalAnalyticsFiltersContext();

  const filterListById = absSectorFilterList?.byId[value];
  const absSubSectorById = absSubSectorFilterList?.byId;
  const isDisabled = !filterListById.canChangeResponse;
  const nestedIds = filterListById?.subSectorIds;
  const { children, activeChildrenCount, checkedChildrenCount } = getChildren(
    nestedIds,
    absSubSectorById,
    formValues,
    filterByChildrenName,
  );
  const groupTitle = filterListById?.absSectorName;

  return (
    <SharedFilterGroupItem
      { ...props }
      groupTitle={ groupTitle }
      isDisabled={ isDisabled }
      nestedIds={ nestedIds }
      activeChildrenCount={ activeChildrenCount }
      checkedChildrenCount={ checkedChildrenCount }
    >
      { children }
    </SharedFilterGroupItem>
  );
};

export default FilterGroupItem;
