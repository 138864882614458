import React from 'react';
import memoize from '@/Framework/dataHelpers/memoize';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import keyResolver from '@/Framework/dataHelpers/keyResolver';
import SharedFilterGroupItem from '@/ui/shared/modules/Filters/components/FilterGroupItem';

const getChildren = memoize((nestedIds, subIndustryById, formValues, filterByChildrenName) => {
  const children = nestedIds.map((subIndustryId) => ({
    value: subIndustryId,
    label: subIndustryById[subIndustryId].subIndustryAbbreviation
      ? subIndustryById[subIndustryId].subIndustryAbbreviation
      : subIndustryById[subIndustryId].subIndustryName,
    disabled: !subIndustryById[subIndustryId].canChangeResponse,
  }));
  const activeChildrenCount = children.filter((child) => !child.disabled).length;
  const checkedChildrenCount = children.filter(
    (child) => !child.disabled && formValues[filterByChildrenName].includes(child.value),
  ).length;

  return {
    children,
    activeChildrenCount,
    checkedChildrenCount,
  };
}, keyResolver);

const FilterGroupItem = (props) => {
  const { value, formValues, filterByChildrenName } = props;

  const { industryFilterList, subIndustryFilterList } = useGlobalAnalyticsFiltersContext();

  const filterListById = industryFilterList?.byId[value];
  const subIndustryById = subIndustryFilterList?.byId;
  const isDisabled = !filterListById.canChangeResponse;
  const nestedIds = filterListById?.subIndustryIds;
  const { children, activeChildrenCount, checkedChildrenCount } = getChildren(
    nestedIds,
    subIndustryById,
    formValues,
    filterByChildrenName,
  );
  const groupTitle = filterListById?.industryName;

  return (
    <SharedFilterGroupItem
      { ...props }
      groupTitle={ groupTitle }
      isDisabled={ isDisabled }
      nestedIds={ nestedIds }
      activeChildrenCount={ activeChildrenCount }
      checkedChildrenCount={ checkedChildrenCount }
    >
      { children }
    </SharedFilterGroupItem>
  );
};

export default FilterGroupItem;
