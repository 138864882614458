import React, { useState, useEffect, useCallback } from 'react';
import { Spinner, Tooltip, IconType, DropdownItem, DropdownItemType } from '@dealroadshow/uikit';
import useRouter from '@/Framework/hooks/useNextRouter';
import { TabLink as NextNavLink } from '@/ui/shared/components/Next/Link';
import PageNotFound from '@/ui/shared/components/Templates/PageNotFound';
import SidebarContainer from './Sidebar/SidebarContainer';
import RoadshowAnalyticsContextWrapper from '@/dealroadshow/application/dmPortal/analytics/RoadshowAnalyticsContextWrapper';
import { useFiltersContext } from '@/dealroadshow/application/dmPortal/analytics/FiltersContext';
import { useRoadshowAnalyticsContext } from '@/dealroadshow/application/dmPortal/analytics/RoadshowAnalyticsContext';
import { useQuestionsMetaContext } from '@/dealroadshow/application/dmPortal/analytics/QuestionsMetaContext';
import { useMeetingsMetaContext } from '@/dealroadshow/application/dmPortal/analytics/MeetingsMetaContext';
import { useInvestorTargetingContext } from '@/dealroadshow/application/dmPortal/analytics/InvestorTargetingContext';
import { useRoadshowRoleContext } from '@/dealroadshow/application/RoadshowRoleContext';
import RoadshowLayout from '@/dealroadshow/ui/components/dmPortal/RoadshowLayout';
import RoadshowMenu, { getItemDataTest } from '@/dealroadshow/ui/components/dmPortal/RoadshowMenu';
import FiltersButton from './FiltersButton';
import {
  ACCOUNTS,
  ALLOCATIONS,
  CONTACTS,
  DEAL_DIGEST,
  ENGAGEMENT,
  QUESTIONS,
  MEETINGS,
  INVESTOR_TARGETING,
  sidebarAllowedRoutes,
  publicRoutes,
} from './constants';

import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';
import pageStyles from '@/ui/shared/styles/page.scss';
import analyticsStyles from '@/ui/shared/modules/Analytics/analytics.scss';

interface IProps {
  children: React.ReactNode,
  name: string,
  isPublic: boolean,
  isMetadataFetching: boolean,
  isDownloadExcelFetching: boolean,
  downloadExcel: (roadshowId: string, filtersPayload: object, targetingFilters: object) => void,
  isAdminAnalyticsOnly: boolean,
  requestMeetingEnabled: boolean,
  getApplicationIsBlacklisted: () => void,
  isBlacklisted: boolean,
  resetMetadata: () => void,
  resetRoadshowSettings: () => void,
  investorQuestionsEnabled: boolean,
}

const isPublicRoute = (pathname: string, hasMeetings: boolean, hasQuestions: boolean) => {
  const currentRoute = pathname.split('/').pop();
  if (
    (currentRoute === MEETINGS && !hasMeetings) ||
    (currentRoute === QUESTIONS && !hasQuestions)
  ) {
    return false;
  }
  return publicRoutes.includes(currentRoute);
};

const isFiltersVisible = (pathname: string) => {
  const currentRoute = pathname.split('/').pop();
  return sidebarAllowedRoutes.includes(currentRoute);
};

const getRelativePath = (roadshowId: string, path: string) => `/roadshow/${ roadshowId }/analytics/${ path }`;

function RoadshowAnalytics(props: IProps) {
  const { asPath: pathname, query: { roadshowId: id } } = useRouter();
  const roadshowId = id?.toString();

  const relativePath = getRelativePath.bind(null, roadshowId);
  const {
    children,
    name,
    isPublic,
    isMetadataFetching,
    isDownloadExcelFetching,
    resetMetadata,
    resetRoadshowSettings,
    isAdminAnalyticsOnly,
    isBlacklisted,
    getApplicationIsBlacklisted,
    investorQuestionsEnabled,
    requestMeetingEnabled,
  } = props;

  const { initRoadshowAnalytics } = useRoadshowAnalyticsContext();
  const { filtersPayload } = useFiltersContext();
  const { getCurrentUserRole } = useRoadshowRoleContext();
  const {
    questionsCounter,
    hasQuestions,
    getQuestionsMeta,
  } = useQuestionsMetaContext();
  const {
    meetingsCounter,
    hasMeetings,
    getMeetingsMeta,
  } = useMeetingsMetaContext();
  const {
    filter: targetingFilters,
  } = useInvestorTargetingContext();

  const [sidebarToggle, setSidebarToggle] = useState(false);

  useEffect(() => {
    initRoadshowAnalytics(roadshowId);
    getQuestionsMeta(roadshowId);
    getMeetingsMeta(roadshowId);
    getCurrentUserRole(roadshowId);
    getApplicationIsBlacklisted();
    return () => {
      resetMetadata();
      resetRoadshowSettings();
    };
  }, []);

  const downloadExcel = useCallback(() => {
    props.downloadExcel(roadshowId, filtersPayload, targetingFilters);
  }, [roadshowId, filtersPayload, targetingFilters]);

  const openSidebar = useCallback(() => {
    setSidebarToggle(true);
  }, [setSidebarToggle]);

  const closeSidebar = useCallback(() => {
    setSidebarToggle(false);
  }, [setSidebarToggle]);

  const isMeetingsLinkVisible = !isPublic || hasMeetings;
  const isQuestionsLinkVisible = !isPublic;
  const openMeetingsMeta = meetingsCounter.open;
  const openQuestionsMeta = questionsCounter.open;

  if (isMetadataFetching) {
    return (
      <Spinner />
    );
  }

  if (isPublic && !isPublicRoute(pathname, hasMeetings, hasQuestions)) {
    return (
      <PageNotFound />
    );
  }

  return (
    <RoadshowLayout
      name={ name }
      actions={
        !isPublic && (
          <>
            { isFiltersVisible(pathname) && (
              <FiltersButton
                className={ spacesStyles.mrm }
                onClick={ openSidebar }
              />
            ) }
            <RoadshowMenu isAdminAnalyticsOnly={ isAdminAnalyticsOnly } roadshowId={ roadshowId }>
              { !isBlacklisted && (
                <DropdownItem
                  type={ DropdownItemType.button }
                  iconType={ IconType.fileXls }
                  onClick={ downloadExcel }
                  loading={ isDownloadExcelFetching }
                  dataTest={ getItemDataTest('exportToXlsButton') }
                >
                  Export to .xls
                </DropdownItem>
              ) }
            </RoadshowMenu>
          </>
        )
      }
      navigations={ (
        <>
          { !isPublic && (
            <NextNavLink
              key={ ACCOUNTS }
              activeClassName={ pageStyles.isActiveNavLink }
              to={ relativePath(ACCOUNTS) }
              dataTest="accountsLink"
            >
              Accounts
            </NextNavLink>
          ) }
          <NextNavLink
            key={ ALLOCATIONS }
            activeClassName={ pageStyles.isActiveNavLink }
            to={ relativePath(ALLOCATIONS) }
            dataTest="allocationsLink"
          >
            Allocations
          </NextNavLink>
          { !isPublic && (
            <NextNavLink
              key={ CONTACTS }
              activeClassName={ pageStyles.isActiveNavLink }
              to={ relativePath(CONTACTS) }
              dataTest="contactsLink"
            >
              Contacts
            </NextNavLink>
          ) }
          <NextNavLink
            key={ DEAL_DIGEST }
            activeClassName={ pageStyles.isActiveNavLink }
            to={ relativePath(DEAL_DIGEST) }
            dataTest="dealDigestLink"
          >
            Deal Digest
          </NextNavLink>
          <NextNavLink
            key={ ENGAGEMENT }
            activeClassName={ pageStyles.isActiveNavLink }
            className={ analyticsStyles.navLink }
            to={ relativePath(ENGAGEMENT) }
            dataTest="engagementLink"
          >
            Engagement
          </NextNavLink>
          { !isPublic && (
            <NextNavLink
              key={ INVESTOR_TARGETING }
              activeClassName={ pageStyles.isActiveNavLink }
              className={ analyticsStyles.navLink }
              to={ relativePath(INVESTOR_TARGETING) }
              dataTest="investorTargetingLink"
            >
              Targeting
            </NextNavLink>
          ) }
          { (isMeetingsLinkVisible || isQuestionsLinkVisible) && (
            <>
              <span className={ pageStyles.separator } />
              { isMeetingsLinkVisible && (
                (requestMeetingEnabled || hasMeetings) ? (
                  <NextNavLink
                    key={ MEETINGS }
                    activeClassName={ pageStyles.isActiveNavLink }
                    className={ analyticsStyles.navLink }
                    to={ relativePath(MEETINGS) }
                    dataTest="meetingsLink"
                  >
                    Meetings
                    { openMeetingsMeta > 0 && <span className={ pageStyles.badge }>{ openMeetingsMeta }</span> }
                  </NextNavLink>
                ) : (
                  // @ts-ignore
                  <Tooltip
                    content="Request Meetings are disabled"
                    wrpClassName={ analyticsStyles.navLinkIsDisable }
                  >
                    <span>Meetings</span>
                  </Tooltip>
                )
              ) }
              { isQuestionsLinkVisible &&
                (investorQuestionsEnabled || hasQuestions) ? (
                  <NextNavLink
                    key={ QUESTIONS }
                    activeClassName={ pageStyles.isActiveNavLink }
                    className={ analyticsStyles.navLink }
                    to={ relativePath(QUESTIONS) }
                    dataTest="questionsLink"
                  >
                    Questions
                    { openQuestionsMeta > 0 && <span className={ pageStyles.badge }>{ openQuestionsMeta }</span> }
                  </NextNavLink>
                ) : (
                  // @ts-ignore
                  <Tooltip
                    content="Investor Questions are disabled"
                    wrpClassName={ analyticsStyles.navLinkIsDisable }
                  >
                    <span>Questions</span>
                  </Tooltip>
              ) }
            </>
          ) }
        </>
      ) }
    >
      { children }
      { !isPublic && (
        <SidebarContainer
          isOpen={ sidebarToggle }
          onSidebarClose={ closeSidebar }
          roadshowId={ roadshowId }
        />
      ) }
    </RoadshowLayout>
  );
}

export default (props) => (
  <RoadshowAnalyticsContextWrapper>
    <RoadshowAnalytics { ...props } />
  </RoadshowAnalyticsContextWrapper>
);
