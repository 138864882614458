import { useCallback, useMemo } from 'react';
import useRouter from '@/Framework/hooks/useNextRouter';
import { Link } from '@/ui/shared/components/Next/Link';
import FinalForm, { Field } from '@/ui/shared/components/Form/FinalForm';
import LinkedRoadshow from '@/allocate/ui/components/shared/Upload/NameAndLink/LinkedRoadshow';
import StepWrapper from '@/allocate/ui/components/shared/Upload/StepWrapper';
import OwnerField from '@/allocate/ui/components/shared/Upload/common/OwnerField/OwnerField';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import { Tooltip, Input, Icon, IconType } from '@dealroadshow/uikit';
import { useUploadContext } from '@/allocate/application/Allocations/Upload/UploadContext';
import { steps, getCancelEditingUrl, getUploadUrl } from '@/allocate/application/Allocations/Upload/config';
import useNameAndLink from '@/allocate/application/Allocations/Upload/Steps/NameAndLink';
import validate from './validation';

import { TFinalFormRenderProp } from '@/ui/shared/components/Form/FinalForm/interfaces';
import { INameAndLinkFormValues } from './interfaces';

import nameAndLinkIcon from '@/allocate/ui/assets/nameAndLinkIcon.svg';
import styles from './nameAndLink.scss';

const NameAndLink = () => {
  const { query: { from } } = useRouter();

  const {
    tenant,
    isEditing,
    isReimporting,
    isInitializing,
    dealAllocationId,
    dealAllocationName,
    linkedRoadshowId,
    linkedRoadshowName,
    ownerId,
    ownerName,
  } = useUploadContext();

  const {
    getRoadshowsList,
    roadshowsList,
    saveNameAndLink,
    isSubmitting,
  } = useNameAndLink();

  const initialValues = useMemo(() => ({
    ...(dealAllocationName && { dealName: dealAllocationName }),
    ...(linkedRoadshowName && { linkedRoadshow: linkedRoadshowName }),
    ...(ownerName && { owner: ownerName }),
    ...(ownerId && { ownerId }),
  }), [isInitializing, isReimporting]);

  const handleSubmit = useCallback(({ dealName, owner, ownerId }: INameAndLinkFormValues) => {
    saveNameAndLink(dealName, owner, ownerId);
  }, [dealAllocationId, linkedRoadshowId]);

  const isLinkedRoadshowValid = useCallback(({ linkedRoadshow }: INameAndLinkFormValues) => (
    (linkedRoadshowId && linkedRoadshowName === linkedRoadshow)
  ), [linkedRoadshowId, linkedRoadshowName]);

  /**
   * Render Form
   */
  const renderForm: TFinalFormRenderProp<INameAndLinkFormValues> = useCallback(({
    submitFailed,
    invalid,
    values,
    errors,
    touched,
    form: { change },
  }) => {
    const onLinkedRoadshowChange = (name: string) => {
      if (!values.dealName) {
        change('dealName', name);
      }
    };

    return (
      <>
        <div className={ styles.wrapper }>
          <div className={ styles.content }>
            <img src={ nameAndLinkIcon } className={ styles.icon } alt="nameAndLinkIcon" />
            <div className={ styles.title }>
              Name (& Link) Allocations
            </div>
            <div className={ styles.description }>
              To the extent these Allocations are tied to a transaction hosted on Deal Roadshow,
              you can link the Allocations to access it via the Deal Roadshow analytics.
            </div>
            <div className={ styles.fieldContainer }>
              <Field
                name="dealName"
                label="Allocate Name"
                placeholder="Allocation Name"
                component={ Input }
                dataTest="dealNameInput"
                className={ styles.field }
              />
              { values.dealName && !errors.dealName && (
                <Icon
                  type={ IconType.check }
                  className={ styles.checkIcon }
                />
              ) }
            </div>
            <div className={ styles.fieldContainer }>
              <OwnerField />
              { values.ownerId && !errors.owner && (
                <Icon
                  type={ IconType.check }
                  className={ styles.checkIcon }
                />
              ) }
            </div>
            <Icon
              type={ IconType.link }
              className={ styles.linkIcon }
            />
            <div className={ styles.fieldContainer }>
              <Field
                name="linkedRoadshow"
                onChange={ onLinkedRoadshowChange }
                component={ LinkedRoadshow }
                roadshowsList={ roadshowsList }
                getRoadshowsList={ getRoadshowsList }
                className={ styles.field }
                dataTest="linkedRoadshowInput"
              />
              { isLinkedRoadshowValid(values) && (
                <Icon
                  type={ IconType.check }
                  className={ styles.checkIcon }
                />
              ) }
            </div>
            <div className={ styles.warningWrapper }>
              <p className={ styles.warning }>
                <Tooltip
                  content="You will not be able to link a roadshow where you are an Analytics Only Deal Manager"
                >
                  <Icon type={ IconType.info } />
                </Tooltip>
                <span>
                  Linking to Deal Roadshow will pull <b>Deal Managers</b> and <b>Enhanced Tagging</b> from the roadshow.
                  Any changes to these sections will need to be made from the roadshow.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className={ styles.buttons }>
          { isEditing ? (
            <Link to={ getCancelEditingUrl(tenant, from?.toString(), dealAllocationId) }>
              <Button
                variant={ variantTypes.text }
                dataTest="nameAndLinkCancelButton"
              >
                Cancel
              </Button>
            </Link>
          ) : (
            <Link to={ getUploadUrl(tenant, steps.linkAccounts) }>
              <Button
                variant={ variantTypes.text }
                dataTest="nameAndLinkBackButton"
              >
                Back
              </Button>
            </Link>
          ) }
          <Button
            type="submit"
            variant={ variantTypes.action }
            className={ styles.saveButton }
            dataTest="nameAndLinkSubmitButton"
            disabled={
              isSubmitting
              || (submitFailed && invalid)
              || (touched.dealName && touched.owner && invalid)
              || (values.linkedRoadshow && !isLinkedRoadshowValid(values))
            }
          >
            { isEditing ? 'Save & Continue' : 'Continue' }
          </Button>
        </div>
      </>
    );
  }, [
    isEditing,
    dealAllocationId,
    dealAllocationName,
    linkedRoadshowName,
    linkedRoadshowId,
    roadshowsList,
    isSubmitting,
    isReimporting,
  ]);

  return (
    <StepWrapper isFetching={ isReimporting }>
      <FinalForm
        onSubmit={ handleSubmit }
        render={ renderForm }
        className={ styles.form }
        validate={ validate }
        name="nameAndLink"
        dataTest="nameAndLinkForm"
        initialValues={ initialValues }
      />
    </StepWrapper>
  );
};

export default NameAndLink;
