import { useState, useEffect } from 'react';
import useRouter from '@/Framework/hooks/useNextRouter';
import { useDIContext } from '@/Framework/DI/DIContext';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { useUploadContext } from '@/allocate/application/Allocations/Upload/UploadContext';
import { RoadshowsCollectionItem } from '@/allocate/domain/vo/Allocations/Upload/NameAndLink';
import { steps, getUploadUrl, changeEditingStep } from '@/allocate/application/Allocations/Upload/config';
import AllocationsUploadRepository from '@/allocate/infrastructure/repository/AllocationsUploadRepository';

const useNameAndLink = () => {
  const { push, query: { from } } = useRouter();
  const { container } = useDIContext();
  const allocationsUploadRepository = container.get<AllocationsUploadRepository>(AllocationsUploadRepository);

  const {
    tenant,
    isEditing,
    isReimport,
    dealAllocationId,
    linkedRoadshowId,
    syncAllocationsData,
    setDealAllocationName,
    setOwnerId,
    setOwnerName,
  } = useUploadContext();

  const [roadshowsList, setRoadshowsList] = useState<RoadshowsCollectionItem[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Get available roadshows for current deal manager
   */
  const getRoadshowsList = async (search: string): Promise<void> => {
    try {
      const { collection } = await allocationsUploadRepository.getRoadshowsList({
        search,
        dealAllocationId,
      });
      setRoadshowsList(collection);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  /**
   * Proceed to next upload/edit step
   */
  const proceedToNextStep = (): void => {
    if (isEditing) {
      changeEditingStep(steps.dealManagers, dealAllocationId, from?.toString());
    } else {
      push(getUploadUrl(tenant, steps.dealManagers));
    }
  };

  /**
   * Wait until linked roadshow data is synced before proceed
   */
  const awaitSyncCompletion = async (): Promise<void> => {
    try {
      const isSynced = await allocationsUploadRepository.getLinkedRoadshowSyncStatus({
        dealAllocationId,
        roadshowId: linkedRoadshowId,
      });

      if (isSynced) {
        proceedToNextStep();
      } else {
        setTimeout(() => awaitSyncCompletion(), 1000);
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  /**
   * Save name and linked roadshow
   */
  const saveNameAndLink = async (
    name: string,
    owner: string,
    ownerId: number,
  ): Promise<void> => {
    setIsSubmitting(true);
    try {
      await allocationsUploadRepository.saveNameAndLink({
        roadshowId: linkedRoadshowId,
        name,
        ownerId,
        dealAllocationId,
      });

      setDealAllocationName(name);
      setOwnerName(owner);
      setOwnerId(ownerId);

      if (linkedRoadshowId) {
        awaitSyncCompletion();
      } else {
        proceedToNextStep();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (isReimport) {
      syncAllocationsData();
    }
  }, []);

  return {
    roadshowsList,
    getRoadshowsList,
    saveNameAndLink,
    isSubmitting,
  };
};

export default useNameAndLink;
