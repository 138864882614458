import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isFinite';
import { Input, InputRange } from '@dealroadshow/uikit';
import filtersStyles from '@/ui/shared/modules/Filters/filters.scss';
import { roundUpValueToFraction } from '@/Framework/dataHelpers/number/roundUpValueToFraction';
import { isEnterKey } from '@/Framework/browser/checkPressedKey';

const noop = (value) => value;

interface IProps {
  value?: { min: number, max: number },
  minValue?: number,
  maxValue?: number,
  step?: number,
  decimals?: number,
  onChange?: Function,
  disabled?: boolean,
  inputValueFormatter?: Function,
}

interface IState {
  isFocused: {
    max: false,
    min: false,
  },
  value: {
    min: number,
    max: number,
  },
  inputValue: {
    min: number,
    max: number,
  },
  minValue: number,
  maxValue: number,
}

class FilterRange extends Component<IProps, IState> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps: Partial<IProps> = {
    value: { min: 0, max: 1 },
    minValue: 0,
    maxValue: 1,
    step: 1,
    decimals: 0,
    onChange: noop,
    disabled: false,
    inputValueFormatter: noop,
  };

  constructor(props) {
    super(props);

    this.state = {
      isFocused: {
        max: false,
        min: false,
      },
      value: {
        min: roundUpValueToFraction(props.value.min, props.decimals),
        max: roundUpValueToFraction(props.value.max, props.decimals),
      },
      inputValue: {
        min: roundUpValueToFraction(props.value.min, props.decimals),
        max: roundUpValueToFraction(props.value.max, props.decimals),
      },
      minValue: roundUpValueToFraction(props.minValue, props.decimals),
      maxValue: roundUpValueToFraction(props.maxValue, props.decimals),
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleRangeChange = this.handleRangeChange.bind(this);
    this.handleRangeBlur = this.handleRangeBlur.bind(this);
    this.handleRangeKeyPress = this.handleRangeKeyPress.bind(this);
    this.valueDecorator = this.valueDecorator.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const value = {
      min: roundUpValueToFraction(props.value.min, props.decimals),
      max: roundUpValueToFraction(props.value.max, props.decimals),
    };
    const rangeValues = {
      minValue: roundUpValueToFraction(props.minValue, props.decimals),
      maxValue: roundUpValueToFraction(props.maxValue, props.decimals),
    };

    const isValueEqual = isEqual(value, state.value);
    const isRangeValuesEqual = isEqual(rangeValues.minValue, state.minValue)
                               && isEqual(rangeValues.maxValue, state.maxValue);

    const newState = {
      ...(!isValueEqual ? { value, inputValue: value } : {}),
      ...(!isRangeValuesEqual ? rangeValues : {}),
    };

    return (!isValueEqual || !isRangeValuesEqual) ? newState : null;
  }

  setRange(event, name) {
    const { decimals } = this.props;
    let value = roundUpValueToFraction(event.target.value, decimals);
    if (isNumber(value)) {
      if (name === 'min') {
        value = (value <= this.state.minValue ? this.state.minValue : value);
        if (value >= this.state.value.max) {
          value = parseFloat(String(this.state.value.max)) - parseFloat(String(this.props.step));
        }
      }
      if (name === 'max') {
        value = (value >= this.state.maxValue ? this.state.maxValue : value);
        if (value <= this.state.value.min) {
          value = parseFloat(String(this.state.value.min)) + parseFloat(String(this.props.step));
        }
      }
      value = roundUpValueToFraction(value, decimals);

      this.setState((prevState) => ({
        inputValue: {
          ...prevState.inputValue,
          [name]: value,
        },
        value: {
          ...prevState.value,
          [name]: value,
        },
      }));

      this.props.onChange({
        ...this.state.value,
        minValue: this.state.minValue,
        maxValue: this.state.maxValue,
        [name]: value,
      });
    } else {
      this
        .setState((prevState) => ({
          inputValue: {
            ...prevState.inputValue,
            [name]: prevState.value[name],
          },
        }));
    }
  }

  handleRangeChange(value) {
    const { decimals } = this.props;
    value = {
      min: roundUpValueToFraction(value.min, decimals),
      max: roundUpValueToFraction(value.max, decimals),
    };
    this.setState({
      value,
      inputValue: value,
    });
    this.props.onChange({
      ...value,
      minValue: this.state.minValue,
      maxValue: this.state.maxValue,
    });
  }

  handleRangeFocus(event, name) {
    this.setState((prevState) => ({
      isFocused: {
        ...prevState.isFocused,
        [name]: true,
      },
    }));
  }

  handleInputChange(event, name) {
    if (this.state.isFocused[name]) {
      let { value } = event.target;
      this.setState((prevState) => ({
        inputValue: {
          ...prevState.inputValue,
          [name]: value,
        },
      }));
    }
  }

  handleRangeBlur(event, name) {
    this.setRange(event, name);
    this.setState((prevState) => ({
      isFocused: {
        ...prevState.isFocused,
        [name]: false,
      },
    }));
  }

  handleRangeKeyPress(event, name) {
    if (isEnterKey(event)) {
      this.setRange(event, name);
    }
  }

  valueDecorator(value, isFocused) {
    return isFocused ? value : this.props.inputValueFormatter(value);
  }

  render() {
    return (
      <>
        <div className={ filtersStyles.filterRangeSlider }>
          <InputRange
            minValue={ this.state.minValue }
            maxValue={ this.state.maxValue }
            step={ this.props.step }
            onChange={ this.handleRangeChange }
            value={ this.state.value }
            disabled={ this.props.disabled || this.state.maxValue === 0 }
          />
        </div>
        <div className={ filtersStyles.filterRangeInput }>
          <div className={ filtersStyles.filterRangeInputMin }>
            { /* @ts-ignore */ }
            <Input
              name="min"
              value={ this.valueDecorator(this.state.inputValue.min, this.state.isFocused.min) }
              onChange={ (event) => this.handleInputChange(event, 'min') }
              onFocus={ (event) => this.handleRangeFocus(event, 'min') }
              onBlur={ (event) => this.handleRangeBlur(event, 'min') }
              onKeyPress={ (event) => this.handleRangeKeyPress(event, 'min') }
              isNarrow
              disabled={ this.props.disabled || this.state.maxValue === 0 }
              isClearable={ false }
              dataTest="filterMinInput"
            />
          </div>
          <div className={ filtersStyles.filterRangeInputMax }>
            { /* @ts-ignore */ }
            <Input
              name="max"
              value={ this.valueDecorator(this.state.inputValue.max, this.state.isFocused.max) }
              onChange={ (event) => this.handleInputChange(event, 'max') }
              onFocus={ (event) => this.handleRangeFocus(event, 'max') }
              onBlur={ (event) => this.handleRangeBlur(event, 'max') }
              onKeyPress={ (event) => this.handleRangeKeyPress(event, 'max') }
              isNarrow
              disabled={ this.props.disabled || this.state.maxValue === 0 }
              isClearable={ false }
              dataTest="filterMaxInput"
            />
          </div>
        </div>
      </>
    );
  }
}

export default FilterRange;
